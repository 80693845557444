import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./Pages/Login";
import ForgotPassword from "./Pages/ForgotPassword";
import ResetPassword from "./Pages/ResetPassword";
import Error from "./Pages/Error";
import UnderMaintenance from "./Pages/UnderMaintenance";
import OtpVarification from "./Pages/OtpVarification";
import MyProfile from "./Pages/MyProfile";
import UserVerification from "./Pages/UserVerification";
import User from "./Pages/User";
import Dashboard from "./Pages/Dashboard";
import Payouts from "./Pages/Payouts";
import Transactions from "./Pages/Transactions";
import ProfileDetails from "./Pages/ProfileDetails";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';  // Import the default styles
import ProtectedRoute from "./Components/common/ProtectedRoute";
import ProtectPublic from "./Components/common/ProtectPublic";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route element={<ProtectPublic />}>
            <Route path="/" element={<Login />}></Route>
            <Route path="/forgot-password" element={<ForgotPassword />}></Route>
            <Route path="/reset-password/:id" element={<ResetPassword />}></Route>
            <Route path="/otp-varification" element={<OtpVarification />}></Route>
          </Route>


          <Route element={<ProtectedRoute />}>
            <Route path="/under-maintenance" element={<UnderMaintenance />}></Route>
            <Route path="/user" element={<User />}></Route>
            <Route path="/dashboard" element={<Dashboard />}></Route>
            <Route path="/my-profile" element={<MyProfile />}></Route>
            <Route path="/payouts" element={<Payouts />}></Route>
            <Route path="/transactions" element={<Transactions />}></Route>
            <Route path="/profile-details" element={<ProfileDetails />}></Route>
            <Route path="/user-verification" element={<UserVerification />}></Route>

          </Route>

          <Route path="*" element={<Error />}></Route>
        </Routes>

      </BrowserRouter>


      <ToastContainer
        position="top-right"

      />
    </div>
  );
}

export default App;
