import React, { useState } from "react";
import { Container, Col, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import { otpValidationSchema } from "../utils/Validations";
import { useFormik } from "formik";
import { postRequest } from "../api/requestHandler";
import { toast } from "react-toastify";

export default function OtpVerification() {
  const [otp, setOtp] = useState("");
  const navigate= useNavigate()
   const userId  = localStorage.getItem("userId")
  // Using useFormik hook
  const formik = useFormik({
    initialValues: { otp: "" },
    validationSchema: otpValidationSchema,
    onSubmit:async (values) => {
      console.log(values)

      const payload ={
        otp:values.otp,
        id :userId
      }

      if (values) {
        try {
          const response = await postRequest('/verify_otp', payload);
          if (response.status == 200) {
            debugger
            toast.success(response?.message) 
            localStorage.setItem("token", response?.data?.token);
            localStorage.setItem("isTokenVerify",true)
            navigate(`/dashboard`);
        } else {
            toast.error(response.payload.message)
        }
        } catch (error) {
          toast.error(error?.response?.data?.message)
        }
      }


    },
  });

  return (
    <div>
      <Container fluid>
        <div className="row sign-banner-part">
          <Col lg={8} className="p-0">
            <div className="left-banner-img">
              <div className="logo">
                <img src={require("../Assets/Images/Logo.svg").default} alt="Logo" />
              </div>
            </div>
          </Col>
          <Col lg={4}>
            <div className="right-banner-part">
              <div className="login-cmn-box">
                <div className="login-box-inner-wrap">
                  <h2>Two Step Verification 💬</h2>
                  <p className="mb-0">
                    We sent a verification code to your mobile. Enter the code
                    from the mobile in the field below.
                    <br />
                    <span>******1234</span>
                  </p>

                  {/* Form using useFormik */}
                  <Form onSubmit={formik.handleSubmit}>
                    <div className="form-set set-otp">
                      {/* OTP Input field */}
                      <OtpInput
                        value={formik.values.otp}
                        onChange={(otp) => formik.setFieldValue("otp", otp)}  // Sync OTP with Formik state
                        numInputs={6}
                        renderInput={(props) => <input {...props} />}
                      />
                      {/* Error Message */}
                      {formik.errors.otp && formik.touched.otp && (
                        <div className="error-message" style={{color:"red"}}>{formik.errors.otp}</div>
                      )}
                    </div>

                    {/* Submit Button */}
                    <button
                      type="submit"
                      className="submit forgot-btn"
                    >
                      Verify my account
                    </button>
                  </Form>

                  {/* Resend OTP link */}
                  <div className="resend-mail">
                    <p>
                      Didn't get the OTP? <Link to="/"> Resend </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </div>
      </Container>
    </div>
  );
}
