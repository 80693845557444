import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoute = () => {
  const isTokenVerify = localStorage.getItem('token');

  return isTokenVerify ? <Outlet /> : <Navigate to='/' />;
};

export default ProtectedRoute;
