import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";

export default function Payouts() {
  return (
    <Layout>
      <Container fluid>
        <div className="filters">
          <div className="inner-filter-field">
            <div className="cmn-head-txt">
              <div>
                <h2>Payouts</h2>
              </div>
              <div className="border-tb">
                <Form>
                  <Form.Group controlId="formBasicPassword">
                    <Form.Control type="text" placeholder="L2T Fees: £8.50" />
                  </Form.Group>
                  <Form.Group controlId="formBasicPassword">
                    <Form.Control
                      type="text"
                      placeholder="Stripe Fees: £3.66"
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicPassword">
                    <Form.Control type="text" placeholder="TOTAL: £8.50" />
                  </Form.Group>
                  <Form.Group controlId="formBasicPassword">
                    <Form.Control type="text" placeholder="TOTAL: £106.80" />
                  </Form.Group>
                </Form>
              </div>
            </div>
            <div className="user-search">
              <div className="drop-down">
                <div className="select-box">
                  <Form.Select aria-label="Default select example">
                    <option>+91</option>
                    <option value="1">9</option>
                  </Form.Select>
                </div>
              </div>

              <div className="search-tab">
                <div className="input-search">
                  <Form>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Control type="text" placeholder="Search User" />
                    </Form.Group>
                  </Form>
                </div>
                <div className="select-box-cmn">
                  <Form.Select aria-label="Default select example">
                    <option>Date From</option>
                    <option value="1">9</option>
                  </Form.Select>
                </div>
                <div className="select-box-cmn">
                  <Form.Select aria-label="Default select example">
                    <option>Date To</option>
                    <option value="1">Date To</option>
                  </Form.Select>
                </div>
                <div className="export-box">
                  <p>
                    <span>
                      <img
                        src={require("../Assets/Images/upload.svg").default}
                      />
                    </span>
                    Export
                  </p>
                </div>
              </div>
            </div>

            <div className="table-responsive">
              <Table size="sm" className="table-cmn">
                <thead>
                  <tr>
                    <th>
                      <div className="user-checkbox">
                        <div className="remember-check">
                          <input
                            type="checkbox"
                            className="red"
                            id="filled-in-box"
                          />
                        </div>
                        Date
                      </div>
                    </th>
                    <th>Time</th>
                    <th>user</th>
                    <th>Type </th>
                    <th>Request</th>
                    <th>Fee</th>
                    <th>Stripe</th>
                    <th>Total INC Fee</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="first-user">
                        <div className="remember-check">
                          <input
                            type="checkbox"
                            className="red"
                            id="filled-in-box"
                          />
                        </div>

                        <div className="cont-num"> 22/05/2024</div>
                      </div>
                    </td>
                    <td>
                      <div className="cont-num">10:30</div>
                    </td>
                    <td>
                      <div className="cont-num">Jordan Stevenson</div>
                    </td>
                    <td>
                      <div className="cont-num">Jordan Stevenson</div>
                    </td>
                    <td>
                      <div className="cont-num">£100.00</div>
                    </td>
                    <td>
                      <div className="cont-num">£10.00</div>
                    </td>

                    <td>
                      <div className="cont-num">(0.37)</div>
                    </td>
                    <td>
                      <div className="cont-num">£10.85</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="first-user">
                        <div className="remember-check">
                          <input
                            type="checkbox"
                            className="red"
                            id="filled-in-box"
                          />
                        </div>

                        <div className="cont-num"> 22/05/2024</div>
                      </div>
                    </td>
                    <td>
                      <div className="cont-num">10:30</div>
                    </td>
                    <td>
                      <div className="cont-num">Jordan Stevenson</div>
                    </td>
                    <td>
                      <div className="cont-num">Jordan Stevenson</div>
                    </td>
                    <td>
                      <div className="cont-num">£100.00</div>
                    </td>
                    <td>
                      <div className="cont-num">£10.00</div>
                    </td>

                    <td>
                      <div className="cont-num">(0.37)</div>
                    </td>
                    <td>
                      <div className="cont-num">£10.85</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="first-user">
                        <div className="remember-check">
                          <input
                            type="checkbox"
                            className="red"
                            id="filled-in-box"
                          />
                        </div>

                        <div className="cont-num"> 22/05/2024</div>
                      </div>
                    </td>
                    <td>
                      <div className="cont-num">10:30</div>
                    </td>
                    <td>
                      <div className="cont-num">Jordan Stevenson</div>
                    </td>
                    <td>
                      <div className="cont-num">Jordan Stevenson</div>
                    </td>
                    <td>
                      <div className="cont-num">£100.00</div>
                    </td>
                    <td>
                      <div className="cont-num">£10.00</div>
                    </td>

                    <td>
                      <div className="cont-num">(0.37)</div>
                    </td>
                    <td>
                      <div className="cont-num">£10.85</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="first-user">
                        <div className="remember-check">
                          <input
                            type="checkbox"
                            className="red"
                            id="filled-in-box"
                          />
                        </div>

                        <div className="cont-num"> 22/05/2024</div>
                      </div>
                    </td>
                    <td>
                      <div className="cont-num">10:30</div>
                    </td>
                    <td>
                      <div className="cont-num">Jordan Stevenson</div>
                    </td>
                    <td>
                      <div className="cont-num">Jordan Stevenson</div>
                    </td>
                    <td>
                      <div className="cont-num">£100.00</div>
                    </td>
                    <td>
                      <div className="cont-num">£10.00</div>
                    </td>

                    <td>
                      <div className="cont-num">(0.37)</div>
                    </td>
                    <td>
                      <div className="cont-num">£10.85</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="first-user">
                        <div className="remember-check">
                          <input
                            type="checkbox"
                            className="red"
                            id="filled-in-box"
                          />
                        </div>

                        <div className="cont-num"> 22/05/2024</div>
                      </div>
                    </td>
                    <td>
                      <div className="cont-num">10:30</div>
                    </td>
                    <td>
                      <div className="cont-num">Jordan Stevenson</div>
                    </td>
                    <td>
                      <div className="cont-num">Jordan Stevenson</div>
                    </td>
                    <td>
                      <div className="cont-num">£100.00</div>
                    </td>
                    <td>
                      <div className="cont-num">£10.00</div>
                    </td>

                    <td>
                      <div className="cont-num">(0.37)</div>
                    </td>
                    <td>
                      <div className="cont-num">£10.85</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="first-user">
                        <div className="remember-check">
                          <input
                            type="checkbox"
                            className="red"
                            id="filled-in-box"
                          />
                        </div>

                        <div className="cont-num"> 22/05/2024</div>
                      </div>
                    </td>
                    <td>
                      <div className="cont-num">10:30</div>
                    </td>
                    <td>
                      <div className="cont-num">Jordan Stevenson</div>
                    </td>
                    <td>
                      <div className="cont-num">Jordan Stevenson</div>
                    </td>
                    <td>
                      <div className="cont-num">£100.00</div>
                    </td>
                    <td>
                      <div className="cont-num">£10.00</div>
                    </td>

                    <td>
                      <div className="cont-num">(0.37)</div>
                    </td>
                    <td>
                      <div className="cont-num">£10.85</div>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <div className="pagination-section">
                <div className="showing-user">
                  <p>Showing 1 to 10 of 50 entries</p>
                </div>
                <div className="pagination-block">
                  <Pagination>
                    <Pagination.First />
                    <Pagination.Prev />
                    <Pagination.Item>{1}</Pagination.Item>
                    <Pagination.Item>{2}</Pagination.Item>
                    <Pagination.Item active>{3}</Pagination.Item>
                    <Pagination.Item>{4}</Pagination.Item>
                    <Pagination.Item>{5}</Pagination.Item>
                    <Pagination.Next />
                    <Pagination.Last />
                  </Pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="made-with">
          <div className="pixinvent">
            <p>
              {" "}
              © 2024, Made with ❤️ by <span>Codobux</span>
            </p>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
