import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";

export default function Dashboard() {
  return (
    <Layout>
      <div className="TBD-set">
        <p>TBD</p>
      </div>
      {/* <Container fluid>
        <div className="dashboard-items">
          <Row>
            <Col xxl={12} xl={12} lg={12}>
              <div className="dashbox-inner-wrap">
                <Row>
                  <Col xxl={3} xl={3} lg={3}>
                    <div className="dash-inner-boxes">
                      <div className="session">
                        <div className="left-session">
                          <p>Session</p>
                          <h2>
                            21,459 <span>(+29%)</span>
                          </h2>
                          <span className="total-users">Total Users</span>
                        </div>
                        <div className="user-icon-se">
                          <img
                            src={
                              require("../Assets/Images/session-user.svg")
                                .default
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xxl={3} xl={3} lg={3}>
                    <div className="dash-inner-boxes">
                      <div className="session">
                        <div className="left-session">
                          <p>Paid Users</p>
                          <h2>
                            4,567 <span> (+18%) </span>
                          </h2>
                          <span className="total-users">
                            Last week analytics
                          </span>
                        </div>
                        <div className="user-icon-se">
                          <img
                            src={
                              require("../Assets/Images/padi-user.svg").default
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xxl={3} xl={3} lg={3}>
                    <div className="dash-inner-boxes">
                      <div className="session">
                        <div className="left-session">
                          <p>Active Users</p>
                          <h2>
                            19,860 <span>(-14%)</span>
                          </h2>
                          <span className="total-users">
                            Last week analytics
                          </span>
                        </div>
                        <div className="user-icon-se">
                          <img
                            src={
                              require("../Assets/Images/active-user.svg")
                                .default
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xxl={3} xl={3} lg={3}>
                    <div className="dash-inner-boxes">
                      <div className="session">
                        <div className="left-session">
                          <p>Pending Users</p>
                          <h2>
                            237<span>(+42%)</span>
                          </h2>
                          <span className="total-users">
                            Last week analytics
                          </span>
                        </div>
                        <div className="user-icon-se">
                          <img
                            src={
                              require("../Assets/Images/pending-user.svg")
                                .default
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </Container> */}
      {/* 
      <Container fluid>
        <div className="filters">
          <div className="inner-filter-field">
            <div className="user-search">
              <div className="drop-down">
                <div className="select-box">
                  <Form.Select aria-label="Default select example">
                    <option>+91</option>
                    <option value="1">9</option>
                  </Form.Select>
                </div>
              </div>
              <div className="search-tab">
                <div className="input-search">
                  <Form>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Control type="text" placeholder="Search User" />
                    </Form.Group>
                  </Form>
                </div>
                <div className="export-box">
                  <p>
                    <span>
                      <img
                        src={require("../Assets/Images/upload.svg").default}
                      />
                    </span>
                    Export
                  </p>
                </div>
              </div>
            </div>

            <div className="table-responsive">
              <Table size="sm" className="table-cmn">
                <thead>
                  <tr>
                    <th>
                      <div className="user-checkbox">
                        <div className="remember-check">
                          <input
                            type="checkbox"
                            className="red"
                            id="filled-in-box"
                          />
                        </div>
                        User
                      </div>
                    </th>
                    <th>Role</th>
                    <th>Contact</th>
                    <th>Billing </th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="first-user">
                        <div className="remember-check">
                          <input
                            type="checkbox"
                            className="red"
                            id="filled-in-box"
                          />
                        </div>
                        <div className="user-profile">
                          <img
                            src={require("../Assets/Images/user.svg").default}
                          />
                          <div className="user-id">
                            <p>Jordan Stevenson</p>
                            <span>jordan.stevenson</span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>
                          <span>
                            <img
                              src={
                                require("../Assets/Images/crown.svg").default
                              }
                            />
                          </span>
                          S. Admin
                        </p>
                      </div>
                    </td>
                    <td>
                      {" "}
                      <div className="cont-num"> +91 98765 45321 </div>
                    </td>
                    <td>
                      <div className="debit">Auto Debit</div>
                    </td>
                    <td>
                      <div className="pending">
                        <p>Pending</p>
                      </div>
                    </td>
                    <td>
                      <div className="action">
                        <img
                          src={
                            require("../Assets/Images/delete-icon.svg").default
                          }
                        />
                        <img
                          src={
                            require("../Assets/Images/view-icon.svg").default
                          }
                        />
                        <img
                          src={require("../Assets/Images/options.svg").default}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="first-user">
                        <div className="remember-check">
                          <input
                            type="checkbox"
                            className="red"
                            id="filled-in-box"
                          />
                        </div>
                        <div className="user-profile">
                          <img src={require("../Assets/Images/user-sec.png")} />
                          <div className="user-id">
                            <p>Richard Payne</p>
                            <span>richard247</span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>
                          <span>
                            <img
                              src={
                                require("../Assets/Images/shield-check.svg")
                                  .default
                              }
                            />
                          </span>
                          Admin
                        </p>
                      </div>
                    </td>
                    <td>
                      <div className="cont-num"> +91 98765 45321 </div>
                    </td>
                    <td>
                      <div className="debit">Auto Debit</div>
                    </td>
                    <td>
                      <div className="active">
                        <p>Active</p>
                      </div>
                    </td>
                    <td>
                      <div className="action">
                        <img
                          src={
                            require("../Assets/Images/delete-icon.svg").default
                          }
                        />
                        <img
                          src={
                            require("../Assets/Images/view-icon.svg").default
                          }
                        />
                        <img
                          src={require("../Assets/Images/options.svg").default}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="first-user">
                        <div className="remember-check">
                          <input
                            type="checkbox"
                            className="red"
                            id="filled-in-box"
                          />
                        </div>
                        <div className="user-profile">
                          <img
                            src={require("../Assets/Images/user-next.png")}
                          />
                          <div className="user-id">
                            <p>Jennifer Summers</p>
                            <span>summers.45</span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>
                          <span>
                            <img
                              src={
                                require("../Assets/Images/table-user.svg")
                                  .default
                              }
                            />
                          </span>
                          User
                        </p>
                      </div>
                    </td>
                    <td>
                      {" "}
                      <div className="cont-num"> +91 98765 45321 </div>
                    </td>
                    <td>
                      <div className="debit">Auto Debit</div>
                    </td>
                    <td>
                      <div className="active">
                        <p>Active</p>
                      </div>
                    </td>
                    <td>
                      <div className="action">
                        <img
                          src={
                            require("../Assets/Images/delete-icon.svg").default
                          }
                        />
                        <img
                          src={
                            require("../Assets/Images/view-icon.svg").default
                          }
                        />
                        <img
                          src={require("../Assets/Images/options.svg").default}
                        />
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div className="first-user">
                        <div className="remember-check">
                          <input
                            type="checkbox"
                            className="red"
                            id="filled-in-box"
                          />
                        </div>
                        <div className="user-profile">
                          <img
                            src={
                              require("../Assets/Images/justion.svg").default
                            }
                          />
                          <div className="user-id">
                            <p>Mr. Justin Richardson</p>
                            <span>jr.3734</span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>
                          <span>
                            <img
                              src={
                                require("../Assets/Images/crown.svg").default
                              }
                            />
                          </span>
                          S. Admin
                        </p>
                      </div>
                    </td>
                    <td>
                      {" "}
                      <div className="cont-num"> +91 98765 45321 </div>
                    </td>
                    <td>
                      <div className="debit">Manual Paypal</div>
                    </td>
                    <td>
                      <div className="pending">
                        <p>Pending</p>
                      </div>
                    </td>
                    <td>
                      <div className="action">
                        <img
                          src={
                            require("../Assets/Images/delete-icon.svg").default
                          }
                        />
                        <img
                          src={
                            require("../Assets/Images/view-icon.svg").default
                          }
                        />
                        <img
                          src={require("../Assets/Images/options.svg").default}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="first-user">
                        <div className="remember-check">
                          <input
                            type="checkbox"
                            className="red"
                            id="filled-in-box"
                          />
                        </div>
                        <div className="user-profile">
                          <img
                            src={
                              require("../Assets/Images/nicholas.svg").default
                            }
                          />
                          <div className="user-id">
                            <p>Nicholas Tanner</p>
                            <span>nicholas.t</span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>
                          <span>
                            <img
                              src={
                                require("../Assets/Images/shield-check.svg")
                                  .default
                              }
                            />
                          </span>
                          Admin
                        </p>
                      </div>
                    </td>
                    <td>
                      {" "}
                      <div className="cont-num"> +91 98765 45321 </div>
                    </td>
                    <td>
                      <div className="debit">Manual Cash</div>
                    </td>
                    <td>
                      <div className="active">
                        <p>Active</p>
                      </div>
                    </td>
                    <td>
                      <div className="action">
                        <img
                          src={
                            require("../Assets/Images/delete-icon.svg").default
                          }
                        />
                        <img
                          src={
                            require("../Assets/Images/view-icon.svg").default
                          }
                        />
                        <img
                          src={require("../Assets/Images/options.svg").default}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="first-user">
                        <div className="remember-check">
                          <input
                            type="checkbox"
                            className="red"
                            id="filled-in-box"
                          />
                        </div>
                        <div className="user-profile">
                          <img
                            src={
                              require("../Assets/Images/crystal.svg").default
                            }
                          />
                          <div className="user-id">
                            <p>Crystal Mays</p>
                            <span>mays.754</span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>
                          <span>
                            <img
                              src={
                                require("../Assets/Images/table-user.svg")
                                  .default
                              }
                            />
                          </span>
                          User
                        </p>
                      </div>
                    </td>
                    <td>
                      {" "}
                      <div className="cont-num"> +91 98765 45321 </div>
                    </td>
                    <td>
                      <div className="debit">Manual Cash</div>
                    </td>
                    <td>
                      <div className="pending">
                        <p>Pending</p>
                      </div>
                    </td>
                    <td>
                      <div className="action">
                        <img
                          src={
                            require("../Assets/Images/delete-icon.svg").default
                          }
                        />
                        <img
                          src={
                            require("../Assets/Images/view-icon.svg").default
                          }
                        />
                        <img
                          src={require("../Assets/Images/options.svg").default}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <div className="pagination-section">
                <div className="showing-user">
                  <p>Showing 1 to 10 of 50 entries</p>
                </div>
                <div className="pagination-block">
                  <Pagination>
                    <Pagination.First />
                    <Pagination.Prev />
                    <Pagination.Item>{1}</Pagination.Item>
                    <Pagination.Item>{2}</Pagination.Item>
                    <Pagination.Item active>{3}</Pagination.Item>
                    <Pagination.Item>{4}</Pagination.Item>
                    <Pagination.Item>{5}</Pagination.Item>
                    <Pagination.Next />
                    <Pagination.Last />
                  </Pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="made-with">
          <div className="pixinvent">
            <p>
              {" "}
              © 2024, Made with ❤️ by <span>Codobux</span>
            </p>
          </div>
        </div>
      </Container> */}
    </Layout>
  );
}
