import React from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik, Field, ErrorMessage } from "formik";
import { forgotPasswordValidationSchema } from "../utils/Validations";
import { postRequest } from "../api/requestHandler";
import { toast } from "react-toastify";

export default function ForgotPassword() {
  return (
    <div>
      <Container fluid>

          <div className="row sign-banner-part">
            <Col lg={8} className="p-0">
              <div className="left-banner-img">
                <div className="logo">
                  <img src={require("../Assets/Images/Logo.svg").default} alt="Logo" />
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="right-banner-part">
                <div className="login-cmn-box">
                  <div className="login-box-inner-wrap">
                    <h2>Forgot Password 🔒</h2>
                    <p className="mb-0">
                      Enter your email and we'll send you instructions to reset
                      your password
                    </p>

                    {/* Formik Form */}
                    <Formik
                      initialValues={{ email: "" }}
                      validationSchema={forgotPasswordValidationSchema}
                      onSubmit={async(values) => {
                        console.log(values);
                        const trimmedValues = {
                          email: values.email.trim(),
                        };
                        if (values) {
                          try {
                            const response = await postRequest('/forgot_password', trimmedValues);
                            if (response.status == 200) {
                              toast.success(response?.message) 
                          } else {
                              toast.error(response.payload.message)
                          }
                          } catch (error) {
                            toast.error(error?.response?.data?.message)
                          }
                        }
                  


                      }}
                    >
                      {({ handleSubmit }) => (
                        <Form onSubmit={handleSubmit}>
                          <div className="form-set">
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                              <Form.Label>Email</Form.Label>
                              <Field
                                type="email"
                                name="email"
                                className="form-control"
                                placeholder="Enter your email"
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="error-message"
                                style={{color:"red"}}
                              />
                            </Form.Group>
                          </div>

                          {/* Submit Button */}
                          <button
                            type="submit"
                            className="submit forgot-btn"
                          >
                            Send Reset Link
                          </button>
                        </Form>
                      )}
                    </Formik>

                    {/* Back to Login Link */}
                    <div className="back-link-ad">
                      <Link to="/">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="7"
                          height="13"
                          viewBox="0 0 7 13"
                          fill="none"
                        >
                          <path
                            d="M6 1.5L1 6.5L6 11.5"
                            stroke="#7367F0"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        Back to login
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </div>
      </Container>
    </div>
  );
}
