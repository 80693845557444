import apiInstance from "./Interceptors";


// GET Request
export const getRequest = async (endpoint ,reqBody) => {
  try {
    const response = await apiInstance.get(endpoint,reqBody);
    return response.data; // or response if you need the entire response
  } catch (error) {
    throw error;
  }
};

// POST Request
export const postRequest = async (endpoint, reqBody) => {
  try {
    const response = await apiInstance.post(endpoint, reqBody);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// PUT Request
export const putRequest = async (endpoint, reqBody) => {
  try {
    const response = await apiInstance.put(endpoint, reqBody);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// DELETE Request
export const deleteRequest = async (endpoint ,reqBody) => {
  try {
    const response = await apiInstance.delete(endpoint ,reqBody);
    return response.data;
  } catch (error) {
    throw error;
  }
};
