import * as Yup from "yup";
 
 
 export const loginValidation = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required")
      .test('no-spaces', 'Email cannot contain spaces', value => !/\s/.test(value)),
    
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required")
      .test('no-spaces', 'Password cannot contain spaces', value => !/\s/.test(value)),
  });
  


  export const forgotPasswordValidationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required")
      .test('no-spaces', 'Email cannot contain spaces', value => !/\s/.test(value)),
  });
  


  export const resetPasswordValidationSchema = Yup.object({
    newPassword: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("New password is required")
      .test('no-spaces', 'Password cannot contain spaces', value => !/\s/.test(value)),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
      .required('Confirm password is required')
      .test('no-spaces', 'Password cannot contain spaces', value => !/\s/.test(value)),
  });



  export const otpValidationSchema = Yup.object({
    otp: Yup.string()
      .length(6, "OTP must be exactly 6 digits")
      .matches(/^[0-9]+$/, "OTP must only contain digits")
      .required("OTP is required"),
  })