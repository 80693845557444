
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectPublic = () => {
  const isTokenVerify = localStorage.getItem('token');

  return isTokenVerify ? <Navigate to='/dashboard' /> : <Outlet />;
};

export default ProtectPublic;
