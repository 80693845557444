import React from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { resetPasswordValidationSchema } from "../utils/Validations";
import { postRequest } from "../api/requestHandler";
import { toast } from "react-toastify";



export default function ResetPassword() {

  const { id } = useParams();


  const navigate = useNavigate();

  return (
    <div>
      <Container fluid>

        <div className="row sign-banner-part">
          <Col lg={8} className="p-0">
            <div className="left-banner-img">
              <div className="logo">
                <img src={require("../Assets/Images/Logo.svg").default} alt="Logo" />
              </div>
            </div>
          </Col>
          <Col lg={4}>
            <div className="right-banner-part">
              <div className="login-cmn-box">
                <div className="login-box-inner-wrap">
                  <h2>Reset Password 🔒</h2>
                  <p className="mb-0">
                    Your new password must be different from previously used
                    passwords
                  </p>

                  {/* Formik Form */}
                  <Formik
                    initialValues={{
                      newPassword: "",
                      confirmPassword: "",
                    }}
                    validationSchema={resetPasswordValidationSchema}
                    onSubmit={async (values) => {
                      // Handle form submission here
                      console.log(values);
                      // You can add your API call for resetting password here
                      const payload = {
                        password: values?.confirmPassword,
                        id: id
                      }
                      if (values) {
                        try {
                          const response = await postRequest('/change_password', payload);
                          if (response.status == 200) {
                            toast.success(response?.message)

                            navigate(`/`);
                          } else {
                            toast.error(response.payload.message)
                          }
                        } catch (error) {
                          toast.error(error?.response?.data?.message)
                        }
                      }


                    }}
                  >
                    {({ handleSubmit }) => (
                      <Form onSubmit={handleSubmit}>
                        <div className="form-set">
                          <Form.Group className="mb-3" controlId="formBasicNewPassword">
                            <Form.Label>New Password</Form.Label>
                            <Field
                              type="password"
                              name="newPassword"
                              className="form-control"
                              placeholder="New Password"
                            />
                            <ErrorMessage
                              name="newPassword"
                              component="div"
                              className="error-message"
                              style={{ color: "red" }}
                            />
                          </Form.Group>

                          <Form.Group className="mb-3" controlId="formBasicConfirmPassword">
                            <Form.Label>Confirm Password</Form.Label>
                            <Field
                              type="password"
                              name="confirmPassword"
                              className="form-control"
                              placeholder="Confirm Password"
                            />
                            <ErrorMessage
                              name="confirmPassword"
                              component="div"
                              className="error-message"
                              style={{ color: "red" }}
                            />
                          </Form.Group>
                        </div>

                        {/* Submit Button */}
                        <button
                          type="submit"
                          className="submit forgot-btn"
                        >
                          Set New Password
                        </button>
                      </Form>
                    )}
                  </Formik>

                  {/* Back to Forgot Password Link */}
                  <div className="back-link-ad">
                    <Link to="/forgot-password">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="7"
                        height="13"
                        viewBox="0 0 7 13"
                        fill="none"
                      >
                        <path
                          d="M6 1.5L1 6.5L6 11.5"
                          stroke="#7367F0"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      Back to login
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </div>

      </Container>
    </div>
  );
}
