import React from "react";
import { Container, Col, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Field, ErrorMessage } from "formik";
import { loginValidation } from "../utils/Validations";
import { postRequest } from "../api/requestHandler";
import { toast } from "react-toastify";
import { useState } from "react";

export default function Login() {
  const navigate = useNavigate()
  const [passwordType, setPasswordType] = useState("password")
  return (
    <div>
      <Container fluid>

        <div className="row sign-banner-part">
          <Col lg={8} className="p-0">
            <div className="left-banner-img">
              <div className="logo">
                <img src={require("../Assets/Images/Logo.svg").default} />
              </div>
            </div>
          </Col>
          <Col lg={4}>
            <div className="right-banner-part">
              <div className="login-cmn-box">
                <div className="login-box-inner-wrap">
                  <h2>Welcome to Vuexy! 👋🏻</h2>
                  <p>Please sign-in to your account and start the adventure</p>
                  <Formik
                    initialValues={{ email: "", password: "" }}
                    validationSchema={loginValidation}
                    onSubmit={async (values) => {
                      const trimmedValues = {
                        email: values.email.trim(),
                        password: values.password.trim(),
                      };
                      console.log(trimmedValues)

                      if (values) {
                        try {
                          const response = await postRequest('/adminLogin', trimmedValues);
                          if (response.status == 200) {
                            toast.success(`${response?.message} ${response?.data?.otp}`)
                            localStorage.setItem("userId", response?.data?._id);
                            navigate(`/otp-varification`);
                          } else {
                            toast.error(response.payload.message)
                          }
                        } catch (error) {
                          toast.error(error?.response?.data?.message)
                        }
                      }

                    }}
                  >
                    {({ handleSubmit }) => (
                      <Form onSubmit={handleSubmit}>
                        <div className="form-set">
                          <Form.Group className="mb-4" controlId="formBasicEmail">
                            <Form.Label>Email</Form.Label>
                            <Field
                              type="email"
                              name="email"
                              className="form-control"
                              placeholder="Enter your email"
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="error-message"
                              style={{ color: "red" }}
                            />
                          </Form.Group>

                          <Form.Group
                            className="mb-3 pass-eys"
                            controlId="formBasicPassword"
                          >
                            <Form.Label>Password</Form.Label>
                            <Field

                              type={passwordType}
                              name="password"
                              placeholder="Password"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="password"
                              component="div"
                              className="error-message"
                              style={{ color: "red" }}
                            />
                            {passwordType === "text" ?
                            <img
                              onClick={() => setPasswordType("password")}
                              src={require("../Assets/Images/eye.svg").default}
                              alt="eye"
                            />:
                            <img
                              onClick={() => setPasswordType("text")}
                              src={require("../Assets/Images/hide-eye.svg").default}
                              alt="eye"
                            />}
                          </Form.Group>
                        </div>
                        <div className="pass-rember-line">
                          <div className="remember-check">
                            <input type="checkbox" className="red" id="filled-in-box" />
                            <Form.Label>Remember Me</Form.Label>
                          </div>
                          <Link to="/forgot-password" className="forgot">
                            Forgot Password?
                          </Link>
                        </div>
                        <button
                          type="submit"
                          className="submit"
                        >
                          Login
                        </button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </Col>
        </div>
      </Container>
    </div>
  );
}
