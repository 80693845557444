import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";

export default function ProfileDetails() {
  return (
    <Layout>
      <Container fluid>
        <div className="filters">
          <div className="inner-filter-field">
            <div className="cmn-head-txt">
              <div className="border-bottom-set">
                <h2 className="mb-4">Profile Details</h2>
                <hr />
                <ul>
                  <li>
                    <Link href="" className="active">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <circle
                          cx="6.75"
                          cy="5.25"
                          r="3"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M2.25 15.75V14.25C2.25 12.5931 3.59315 11.25 5.25 11.25H8.25C9.90685 11.25 11.25 12.5931 11.25 14.25V15.75"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12 2.34741C13.3274 2.68729 14.2559 3.88341 14.2559 5.25366C14.2559 6.62392 13.3274 7.82003 12 8.15991"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M15.75 15.7498V14.2498C15.7422 12.8882 14.8184 11.7027 13.5 11.3623"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      Overview
                    </Link>
                  </li>
                  <li>
                    <Link href="">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <circle
                          cx="6.75"
                          cy="5.25"
                          r="3"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M2.25 15.75V14.25C2.25 12.5931 3.59315 11.25 5.25 11.25H8.25C9.90685 11.25 11.25 12.5931 11.25 14.25V15.75"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12 2.34741C13.3274 2.68729 14.2559 3.88341 14.2559 5.25366C14.2559 6.62392 13.3274 7.82003 12 8.15991"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M15.75 15.7498V14.2498C15.7422 12.8882 14.8184 11.7027 13.5 11.3623"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      Account details
                    </Link>
                  </li>
                  <li>
                    <Link href="">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <circle
                          cx="6.75"
                          cy="5.25"
                          r="3"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M2.25 15.75V14.25C2.25 12.5931 3.59315 11.25 5.25 11.25H8.25C9.90685 11.25 11.25 12.5931 11.25 14.25V15.75"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12 2.34741C13.3274 2.68729 14.2559 3.88341 14.2559 5.25366C14.2559 6.62392 13.3274 7.82003 12 8.15991"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M15.75 15.7498V14.2498C15.7422 12.8882 14.8184 11.7027 13.5 11.3623"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      transactions
                    </Link>
                  </li>
                  <li>
                    <Link href="">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <circle
                          cx="6.75"
                          cy="5.25"
                          r="3"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M2.25 15.75V14.25C2.25 12.5931 3.59315 11.25 5.25 11.25H8.25C9.90685 11.25 11.25 12.5931 11.25 14.25V15.75"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12 2.34741C13.3274 2.68729 14.2559 3.88341 14.2559 5.25366C14.2559 6.62392 13.3274 7.82003 12 8.15991"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M15.75 15.7498V14.2498C15.7422 12.8882 14.8184 11.7027 13.5 11.3623"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      payouts
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="my-profile-page mt-4">
          <div className="profile-top-box">
            <div className="photo-upload">
              <div className="profile-picture">
                <img src={require("../Assets/Images/my-profile.svg").default} />
              </div>
              <div className="upload-box">
                <h2>Simon Jameson</h2>
                <p className="image-disp">joined: 01/02//2024</p>
              </div>
            </div>

            <div className="profile-disp mt-4">
              <div className="profile-disc-set">
                <ul>
                  <li>
                    <p>Position: </p>
                    <div>@shallamb</div>
                  </li>
                  <li>
                    <p>Empolyer: </p>
                    <div>shallamb@gmail.com</div>
                  </li>
                  <li>
                    <p>Bio: </p>
                    <div>Active</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="filters">
          <div className="inner-filter-field">
            <div className="cmn-head-txt d-flex justify-content-between">
              <div className="photo-upload">
                <div className="profile-picture">
                  <img
                    src={require("../Assets/Images/my-profile.svg").default}
                  />
                </div>
                <div className="upload-box">
                  <h2>Simon Jameson</h2>
                  <p className="image-disp">joined: 01/02//2024</p>
                </div>
              </div>
              <div className="transaction-tble-price">
                <ul>
                  <li>
                    <div>
                      <div>
                        <h3>Available</h3>
                        <p>Pending</p>
                      </div>
                      <div>
                        <h3>£39.15</h3>
                        <div>£0.00</div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div>
                      <div>
                        <h3>Total Sent</h3>
                        <p>£42.55</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div>
                      <div>
                        <h3>Total Received</h3>
                        <p>£49.15</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="user-search">
              <div className="drop-down">
                <div className="select-box">
                  <Form.Select aria-label="Default select example">
                    <option>+91</option>
                    <option value="1">9</option>
                  </Form.Select>
                </div>
              </div>

              <div className="search-tab">
                <div className="input-search">
                  <Form>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Control type="text" placeholder="Search User" />
                    </Form.Group>
                  </Form>
                </div>
                <div className="select-box-cmn">
                  <Form.Select aria-label="Default select example">
                    <option>Date From</option>
                    <option value="1">9</option>
                  </Form.Select>
                </div>
                <div className="select-box-cmn">
                  <Form.Select aria-label="Default select example">
                    <option>Date To</option>
                    <option value="1">Date To</option>
                  </Form.Select>
                </div>
                <div className="export-box">
                  <p>
                    <span>
                      <img
                        src={require("../Assets/Images/upload.svg").default}
                      />
                    </span>
                    Export
                  </p>
                </div>
              </div>
            </div>

            <div className="table-responsive">
              <Table size="sm" className="table-cmn">
                <thead>
                  <tr>
                    <th>
                      <div className="user-checkbox">
                        <div className="remember-check">
                          <input
                            type="checkbox"
                            className="red"
                            id="filled-in-box"
                          />
                        </div>
                        Date
                      </div>
                    </th>
                    <th>Time</th>
                    <th>Sender</th>
                    <th>Receiver </th>
                    <th>Fee paid By </th>
                    <th>Amount</th>
                    <th>Fee</th>
                    <th>Stripe</th>
                    <th>Total INC Fee</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="first-user">
                        <div className="remember-check">
                          <input
                            type="checkbox"
                            className="red"
                            id="filled-in-box"
                          />
                        </div>

                        <div className="cont-num"> 22/05/2024</div>
                      </div>
                    </td>
                    <td>
                      <div className="cont-num">10:30</div>
                    </td>
                    <td>
                      <div className="cont-num">Jordan Stevenson</div>
                    </td>
                    <td>
                      <div className="cont-num">Jordan Stevenson</div>
                    </td>
                    <td>
                      <div className="cont-num">Sender</div>
                    </td>
                    <td>
                      <div className="cont-num">£10.00</div>
                    </td>
                    <td>
                      <div className="cont-num">£0.85</div>
                    </td>
                    <td>
                      <div className="cont-num">(0.37)</div>
                    </td>
                    <td>
                      <div className="cont-num">£10.85</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="first-user">
                        <div className="remember-check">
                          <input
                            type="checkbox"
                            className="red"
                            id="filled-in-box"
                          />
                        </div>

                        <div className="cont-num"> 22/05/2024</div>
                      </div>
                    </td>
                    <td>
                      <div className="cont-num">10:30</div>
                    </td>
                    <td>
                      <div className="cont-num">Jordan Stevenson</div>
                    </td>
                    <td>
                      <div className="cont-num">Jordan Stevenson</div>
                    </td>
                    <td>
                      <div className="cont-num">Sender</div>
                    </td>
                    <td>
                      <div className="cont-num">£10.00</div>
                    </td>
                    <td>
                      <div className="cont-num">£0.85</div>
                    </td>
                    <td>
                      <div className="cont-num">(0.37)</div>
                    </td>
                    <td>
                      <div className="cont-num">£10.85</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="first-user">
                        <div className="remember-check">
                          <input
                            type="checkbox"
                            className="red"
                            id="filled-in-box"
                          />
                        </div>

                        <div className="cont-num"> 22/05/2024</div>
                      </div>
                    </td>
                    <td>
                      <div className="cont-num">10:30</div>
                    </td>
                    <td>
                      <div className="cont-num">Jordan Stevenson</div>
                    </td>
                    <td>
                      <div className="cont-num">Jordan Stevenson</div>
                    </td>
                    <td>
                      <div className="cont-num">Sender</div>
                    </td>
                    <td>
                      <div className="cont-num">£10.00</div>
                    </td>
                    <td>
                      <div className="cont-num">£0.85</div>
                    </td>
                    <td>
                      <div className="cont-num">(0.37)</div>
                    </td>
                    <td>
                      <div className="cont-num">£10.85</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="first-user">
                        <div className="remember-check">
                          <input
                            type="checkbox"
                            className="red"
                            id="filled-in-box"
                          />
                        </div>

                        <div className="cont-num"> 22/05/2024</div>
                      </div>
                    </td>
                    <td>
                      <div className="cont-num">10:30</div>
                    </td>
                    <td>
                      <div className="cont-num">Jordan Stevenson</div>
                    </td>
                    <td>
                      <div className="cont-num">Jordan Stevenson</div>
                    </td>
                    <td>
                      <div className="cont-num">Sender</div>
                    </td>
                    <td>
                      <div className="cont-num">£10.00</div>
                    </td>
                    <td>
                      <div className="cont-num">£0.85</div>
                    </td>
                    <td>
                      <div className="cont-num">(0.37)</div>
                    </td>
                    <td>
                      <div className="cont-num">£10.85</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="first-user">
                        <div className="remember-check">
                          <input
                            type="checkbox"
                            className="red"
                            id="filled-in-box"
                          />
                        </div>

                        <div className="cont-num"> 22/05/2024</div>
                      </div>
                    </td>
                    <td>
                      <div className="cont-num">10:30</div>
                    </td>
                    <td>
                      <div className="cont-num">Jordan Stevenson</div>
                    </td>
                    <td>
                      <div className="cont-num">Jordan Stevenson</div>
                    </td>
                    <td>
                      <div className="cont-num">Sender</div>
                    </td>
                    <td>
                      <div className="cont-num">£10.00</div>
                    </td>
                    <td>
                      <div className="cont-num">£0.85</div>
                    </td>
                    <td>
                      <div className="cont-num">(0.37)</div>
                    </td>
                    <td>
                      <div className="cont-num">£10.85</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="first-user">
                        <div className="remember-check">
                          <input
                            type="checkbox"
                            className="red"
                            id="filled-in-box"
                          />
                        </div>

                        <div className="cont-num"> 22/05/2024</div>
                      </div>
                    </td>
                    <td>
                      <div className="cont-num">10:30</div>
                    </td>
                    <td>
                      <div className="cont-num">Jordan Stevenson</div>
                    </td>
                    <td>
                      <div className="cont-num">Jordan Stevenson</div>
                    </td>
                    <td>
                      <div className="cont-num">Sender</div>
                    </td>
                    <td>
                      <div className="cont-num">£10.00</div>
                    </td>
                    <td>
                      <div className="cont-num">£0.85</div>
                    </td>
                    <td>
                      <div className="cont-num">(0.37)</div>
                    </td>
                    <td>
                      <div className="cont-num">£10.85</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="first-user">
                        <div className="remember-check">
                          <input
                            type="checkbox"
                            className="red"
                            id="filled-in-box"
                          />
                        </div>

                        <div className="cont-num"> 22/05/2024</div>
                      </div>
                    </td>
                    <td>
                      <div className="cont-num">10:30</div>
                    </td>
                    <td>
                      <div className="cont-num">Jordan Stevenson</div>
                    </td>
                    <td>
                      <div className="cont-num">Jordan Stevenson</div>
                    </td>
                    <td>
                      <div className="cont-num">Sender</div>
                    </td>
                    <td>
                      <div className="cont-num">£10.00</div>
                    </td>
                    <td>
                      <div className="cont-num">£0.85</div>
                    </td>
                    <td>
                      <div className="cont-num">(0.37)</div>
                    </td>
                    <td>
                      <div className="cont-num">£10.85</div>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <div className="pagination-section">
                <div className="showing-user">
                  <p>Showing 1 to 10 of 50 entries</p>
                </div>
                <div className="pagination-block">
                  <Pagination>
                    <Pagination.First />
                    <Pagination.Prev />
                    <Pagination.Item>{1}</Pagination.Item>
                    <Pagination.Item>{2}</Pagination.Item>
                    <Pagination.Item active>{3}</Pagination.Item>
                    <Pagination.Item>{4}</Pagination.Item>
                    <Pagination.Item>{5}</Pagination.Item>
                    <Pagination.Next />
                    <Pagination.Last />
                  </Pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="made-with">
          <div className="pixinvent">
            <p>
              {" "}
              © 2024, Made with ❤️ by <span>Codobux</span>
            </p>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
