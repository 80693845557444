import axios from 'axios';

// Create a new Axios instance with custom configuration
const apiInstance = axios.create({
//   baseURL: 'https://your-api-base-url.com', // Replace with your API base URL
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json', // Default headers
  },
});

// Add a request interceptor to include the authentication token
apiInstance.interceptors.request.use(
  function (config) {
    // Retrieve the token (adjust based on where you store the token)
    const token = localStorage.getItem('token') || sessionStorage.getItem('token');

    if (token) {
      config.headers['Authorization'] = `${token}`;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
apiInstance.interceptors.response.use(
  function (response) {
    // Process the response
    return response;
  },
  function (error) {
    // Handle errors (e.g., unauthorized access)
    if (error.response && error.response.status === 401) {
      // Handle unauthorized errors, like redirecting to login
    }
    return Promise.reject(error);
  }
);

export default apiInstance;
