import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function Sidebar({ isActive, setIsActive, setShow, show }) {
  return (
    <aside>
      <div className="sidebar">
        <div className="logo-side">
          <Link to="/user">
            <img
              className="big"
              src={require("../../Assets/Images/side-bar-logo.svg").default}
            />
          </Link>
        </div>
        <div className="side-menu">
          <div>
            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == "/dashboard"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/dashboard"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="2.5"
                    y="10"
                    width="5"
                    height="6.66667"
                    rx="1"
                    stroke="#2F2B3D"
                    strokeOpacity="0.9"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <rect
                    x="7.5"
                    y="6.66675"
                    width="5"
                    height="10"
                    rx="1"
                    stroke="#2F2B3D"
                    strokeOpacity="0.9"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <rect
                    x="12.5"
                    y="3.33325"
                    width="5"
                    height="13.3333"
                    rx="1"
                    stroke="#2F2B3D"
                    strokeOpacity="0.9"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3.3335 16.6667H15.0002"
                    stroke="#2F2B3D"
                    strokeOpacity="0.9"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <p> Dashboard</p>
              </Link>
            </div>

            <div className="side-pages">
              <p>PAGES</p>
            </div>
            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == "/transactions"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/transactions"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <path
                    d="M19.25 6.41671H2.75"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16.5 9.16663L19.25 6.41663L16.5 3.66663"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.5 18.3334L2.75 15.5834L5.5 12.8334"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2.75 15.5833H19.25"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <p> Transaction</p>
              </Link>
            </div>

            <div className="side-btm-space fill-icon">
              <Link
                className={
                  window.location.pathname == "/payouts"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/payouts"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                >
                  <path
                    d="M9.76462 0C10.2567 0 10.7487 0 11.2408 0C11.2931 0.0129236 11.3448 0.0336781 11.3978 0.0376208C12.439 0.115108 13.4517 0.335521 14.4084 0.747598C17.9213 2.26065 20.0843 4.88397 20.836 8.64633C20.9091 9.01247 20.9462 9.38577 21 9.75579C21 10.2477 21 10.7396 21 11.2314C20.9865 11.2906 20.9688 11.3492 20.9602 11.409C20.906 11.7867 20.8727 12.1683 20.7982 12.5419C19.6139 18.4716 13.691 22.1835 7.85822 20.656C2.59307 19.277 -0.738878 14.1549 0.140399 8.77261C0.451989 6.86534 1.23896 5.16714 2.5 3.70015C4.12845 1.80575 6.1706 0.615898 8.63412 0.165871C9.0086 0.0974199 9.38768 0.0546515 9.76462 0ZM10.5179 1.31125C5.48158 1.28946 1.35186 5.39928 1.31735 10.4675C1.28306 15.5017 5.41562 19.6519 10.4865 19.676C15.5213 19.6999 19.6697 15.5903 19.6827 10.5194C19.6958 5.45491 15.6256 1.34685 10.5179 1.31125Z"
                    fill="white"
                  />
                  <path
                    d="M11.158 15.6747C11.158 15.9194 11.1608 16.1578 11.1573 16.3962C11.1518 16.7759 10.8666 17.0548 10.4923 17.051C10.1333 17.0475 9.853 16.7645 9.84807 16.3959C9.84489 16.1575 9.84746 15.919 9.84746 15.6719C9.29292 15.5298 8.81031 15.2752 8.41376 14.8712C7.85308 14.3 7.55858 13.6158 7.55212 12.8133C7.549 12.4265 7.82361 12.1393 8.19129 12.134C8.56643 12.1287 8.84444 12.3981 8.86208 12.784C8.90125 13.642 9.43765 14.2615 10.263 14.4018C11.1187 14.5473 11.9633 13.9422 12.1081 13.0801C12.2593 12.1797 11.6786 11.3292 10.8052 11.1876C10.4764 11.1342 10.1368 11.1364 9.81345 11.0631C8.5455 10.7757 7.5794 9.60726 7.55223 8.35685C7.52106 6.92381 8.3633 5.74272 9.68586 5.36416C9.73703 5.34954 9.78693 5.33048 9.84752 5.31005C9.84752 5.06703 9.84483 4.82854 9.84812 4.59017C9.85332 4.20958 10.1369 3.93221 10.5126 3.93583C10.8734 3.93928 11.1521 4.22004 11.1573 4.59033C11.1607 4.82871 11.158 5.06719 11.158 5.31339C11.8877 5.49816 12.4759 5.88214 12.9086 6.48862C13.2671 6.991 13.4535 7.55279 13.4539 8.17242C13.4541 8.55771 13.1802 8.84735 12.8143 8.85282C12.4418 8.85846 12.1613 8.58663 12.1434 8.20275C12.1033 7.3435 11.5685 6.72585 10.7426 6.58489C9.89742 6.44065 9.04614 7.04138 8.89944 7.88552C8.74238 8.78897 9.3127 9.64916 10.1793 9.79652C10.5142 9.85347 10.8614 9.84871 11.1913 9.92345C12.4524 10.2092 13.4169 11.3672 13.4528 12.6086C13.4937 14.0285 12.666 15.2177 11.3606 15.6146C11.3018 15.6325 11.2429 15.6495 11.158 15.6747Z"
                    fill="white"
                  />
                </svg>  

                <p>Payouts</p>
              </Link>
            </div>
            {/* <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == ""
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to=""
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="3.6665"
                    y="11.9165"
                    width="3.66667"
                    height="5.5"
                    rx="1.83333"
                    stroke="#2F2B3D"
                    strokeOpacity="0.9"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <rect
                    x="14.6665"
                    y="11.9165"
                    width="3.66667"
                    height="5.5"
                    rx="1.83333"
                    stroke="#2F2B3D"
                    strokeOpacity="0.9"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3.6665 13.7498V10.9998C3.6665 6.94975 6.94975 3.6665 10.9998 3.6665C15.0499 3.6665 18.3332 6.94975 18.3332 10.9998V13.7498"
                    stroke="#2F2B3D"
                    strokeOpacity="0.9"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16.5 17.4165C16.5 18.9353 14.0376 20.1665 11 20.1665"
                    stroke="#2F2B3D"
                    strokeOpacity="0.9"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <p>Reviews</p>
              </Link>
            </div>

            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == ""
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to=""
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.1665 4.58333C9.1665 3.57081 9.98732 2.75 10.9998 2.75C12.0124 2.75 12.8332 3.57081 12.8332 4.58333C14.9783 5.59767 16.3886 7.71306 16.4998 10.0833V12.8333C16.6397 13.9891 17.3201 15.0097 18.3332 15.5833H3.6665C4.67953 15.0097 5.35994 13.9891 5.49984 12.8333V10.0833C5.61109 7.71306 7.02135 5.59767 9.1665 4.58333"
                    stroke="#2F2B3D"
                    strokeOpacity="0.9"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.25 15.5835V16.5002C8.25 18.0189 9.48122 19.2502 11 19.2502C12.5188 19.2502 13.75 18.0189 13.75 16.5002V15.5835"
                    stroke="#2F2B3D"
                    strokeOpacity="0.9"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <p>Newsfeed</p>
              </Link>
            </div> */}
            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == "/user"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/user"
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <ellipse
                    cx="11.0002"
                    cy="6.41667"
                    rx="3.66667"
                    ry="3.66667"
                    stroke="#2F2B3D"
                    strokeOpacity="0.9"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.5 19.25V17.4167C5.5 15.3916 7.14162 13.75 9.16667 13.75H12.8333C14.8584 13.75 16.5 15.3916 16.5 17.4167V19.25"
                    stroke="#2F2B3D"
                    strokeOpacity="0.9"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <p> User</p>
              </Link>
            </div>

            {/* <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == ""
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to=""
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <ellipse
                    cx="8.25016"
                    cy="6.41667"
                    rx="3.66667"
                    ry="3.66667"
                    stroke="#2F2B3D"
                    strokeOpacity="0.9"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2.75 19.25V17.4167C2.75 15.3916 4.39162 13.75 6.41667 13.75H10.0833C12.1084 13.75 13.75 15.3916 13.75 17.4167V19.25"
                    stroke="#2F2B3D"
                    strokeOpacity="0.9"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14.6665 10.0833L16.4998 11.9167L20.1665 8.25"
                    stroke="#2F2B3D"
                    strokeOpacity="0.9"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <p>Verification Request</p>
              </Link>
            </div> */}
            {/* <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == ""
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to=""
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="3.6665"
                    y="11.9165"
                    width="3.66667"
                    height="5.5"
                    rx="1.83333"
                    stroke="#2F2B3D"
                    strokeOpacity="0.9"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <rect
                    x="14.6665"
                    y="11.9165"
                    width="3.66667"
                    height="5.5"
                    rx="1.83333"
                    stroke="#2F2B3D"
                    strokeOpacity="0.9"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3.6665 13.7498V10.9998C3.6665 6.94975 6.94975 3.6665 10.9998 3.6665C15.0499 3.6665 18.3332 6.94975 18.3332 10.9998V13.7498"
                    stroke="#2F2B3D"
                    strokeOpacity="0.9"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16.5 17.4165C16.5 18.9353 14.0376 20.1665 11 20.1665"
                    stroke="#2F2B3D"
                    strokeOpacity="0.9"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <p>Help & Support</p>
              </Link>
            </div> */}
            {/* <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == ""
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to=""
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="2.5"
                    y="10"
                    width="5"
                    height="6.66667"
                    rx="1"
                    stroke="#2F2B3D"
                    strokeOpacity="0.9"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <rect
                    x="7.5"
                    y="6.66675"
                    width="5"
                    height="10"
                    rx="1"
                    stroke="#2F2B3D"
                    strokeOpacity="0.9"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <rect
                    x="12.5"
                    y="3.33325"
                    width="5"
                    height="13.3333"
                    rx="1"
                    stroke="#2F2B3D"
                    strokeOpacity="0.9"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3.3335 16.6667H15.0002"
                    stroke="#2F2B3D"
                    strokeOpacity="0.9"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <p> Manage Admin</p>
              </Link>
            </div> */}
          </div>
        </div>
      </div>
    </aside>
  );
}
